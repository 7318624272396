<template>
  <section id="hero">
    <!-- !-->
    <v-parallax dark src="@/assets/images/04.jpg" height="750">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" xl="8">
              <h1 class="display-2 font-weight-bold mb-4">Landing Page 01</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur <br />
                adipisicing elit. Maiores porro voluptatibus <br />
                delectus nam optio harum!
              </h1>
              <v-btn rounded outlined large dark class="mt-5">
                Button
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-parallax>
    <!-- features !-->
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, index) in features"
              :key="index"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <!-- feature icon !-->
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <!-- feature title !-->
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <!-- feature text !-->
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "LandingPage01",
  data() {
    return {
      features: [
        {
          img: require("@/assets/images/icon2.png"),
          title: "Feature 01",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
          img: require("@/assets/images/icon2.png"),
          title: "Feature 02",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        },
        {
          img: require("@/assets/images/icon2.png"),
          title: "Feature 03",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit."
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>
