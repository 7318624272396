












































import Vue from "vue";
//import TodoItem from "@/components/ToDo/TodoItem.vue";
import TaskCard from "@/components/ToDo/TaskCard.vue";
import draggable from "vuedraggable";

export default Vue.extend({
  name: "TodoList",
  components: {
    //TodoItem
    TaskCard,
    draggable
  },
  data() {
    return {
      newTask: "",
      idForTodo: 3
    };
  },
  methods: {
    addTask(column: any) {
      console.log(column);
      if (column.newTask.trim().length == 0) {
        return;
      }
      this.$store.dispatch("addTask", {
        id: this.idForTodo,
        title: column.newTask,
        column: column.id
      });

      (column.newTask = ""), this.idForTodo++;
    }
  }
});
