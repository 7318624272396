<template>
  <div class="LandingPage02">
    <v-parallax dark src="@/assets/images/04.jpg">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" xl="8">
              <h1 class="display-2 font-weight-bold mb-4">Landing Page 02</h1>
              <h1 class="font-weight-light">
                Lorem ipsum dolor sit amet consectetur <br />
                adipisicing elit. Maiores porro voluptatibus <br />
                delectus nam optio harum!
              </h1>
              <v-btn rounded outlined large dark class="mt-5">
                Button
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-parallax>

    <v-container>
      <v-row dense>
        <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
          <v-card v-if="card.visible">
            <v-system-bar color="orange darken-4" dark>
              <v-spacer></v-spacer>

              <v-icon>mdi-window-minimize</v-icon>

              <v-icon>mdi-window-maximize</v-icon>

              <v-btn icon x-small @click="hide(card)"> <v-icon>mdi-close</v-icon></v-btn>
            </v-system-bar>
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="500px"
            >
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div style="height: 100vh;"></div>
  </div>
</template>

<script>
export default {
  name: "LandingPage01",
  data: () => ({
    cards: [
      {
        title: "Home",
        src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
        flex: 12,
        visible: true
      },
      {
        title: "Road trips",
        src: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
        flex: 6,
        visible: true
      },
      {
        title: "Travel",
        src: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
        flex: 6,
        visible: true
      }
    ]
  }),
  methods: {
    hide(card) {
      card.visible = false;
    },
    maximize(card) {
      card.height = "600px";
    }
  }
};
</script>

<style lang="scss"></style>
