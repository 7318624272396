





















import Vue from "vue";

export default Vue.extend({
  name: "TaskCard",
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return { id: this.task.id, title: this.task.title };
  },
  methods: {
    removeTask(id: number) {
      this.$store.commit("deleteTask", id);
    }
  }
});
