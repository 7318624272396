








import Vue from "vue";
import TodoList from "@/components/ToDo/TodoList.vue";
export default Vue.extend({
  name: "Todo",
  // eslint-disable-next-line vue/no-unused-components
  components: { TodoList },
  data: () => ({})
});
